a {
  color: blue;
}

h1 {
  line-height: 3rem;
}

.questionBox {
  width: 70% !important;

  padding: 2rem !important;
  background-color: transparent !important;
  max-width: 1500px !important;
  /* background-color: #fffaf5 !important; */

  /* border: 2px solid #e7e7e7; */
}

.dashboard {
  width: 72%;
  padding-top: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
  padding-bottom: 40px !important;
  max-width: 1500px !important;
}

.dashboardArticle {
  width: 72%;
  padding-top: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
  padding-bottom: 40px !important;
  max-width: 1500px !important;
}

.quizdashboard {
  width: 80%;
  padding-top: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
  padding-bottom: 40px !important;
  max-width: 1500px !important;
}

.dashboardTopics {
  width: 70%;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
}

.instructions {
  width: 70%;
  padding: 1rem;
  background-color: transparent !important;
  max-width: 1500px !important;
}

.questionBoxInner,
.questionTitleInner {
  text-align: left !important;
  width: 90%;
  font-weight: 500;
}

.questionBoxInner:hover {
  /*  background-color: #ffebee; */
  background-color: #f7f0e8;
  cursor: pointer;
}

.optionText {
  cursor: pointer;
  align-items: flex-start !important;
  font-size: 19px;
}

hr {
  width: 90%;
  font-weight: lighter !important;
}

#questionTitle {
  color: black;
  font-size: 18px !important;
}

#buttonDesign {
  cursor: pointer;
  background-color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 22px;
  padding-right: 22px;
  color: white;
}

#quizInfo {
  cursor: pointer;
  position: fixed !important;
  bottom: 40px !important;

  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px;
  padding-right: 18px;
}

#exitButton,
#nextButton,
#revealAnswerButton {
  cursor: pointer;
  background-color: black;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px;
  padding-right: 18px;

  color: white;
}

.mobileLoginButton {
  display: none;
}

@media only screen and (max-width: 1000px) {
  #socialSection {
    margin-top: 200px !important;
  }
  .logoDescription {
    width: 100%;
  }
  #gapSection {
    display: none;
  }
  #nextButton,
  #revealAnswerButton,
  #exitButton {
    position: relative;
    top: 40px !important;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px;
    padding-right: 24px;
  }
  #exitButton {
    margin-right: 10px;
  }
  #nextButton {
    margin-left: 10px;
  }
  .dashboard {
    width: 100%;
    max-width: 2000px !important;
  }
  .dashboardArticle {
    width: 72% !important;
    max-width: 2000px !important;
  }
  .questionBox {
    width: 100%;
  }
  .oppPost {
    width: 500px !important;
  }
  .blogPost {
    width: 500px !important;
  }
  .mobileLoginButton {
    display: none;
  }
}

@media only screen and (max-width: 1800px) {
  #socialSection {
    margin-top: 60px !important;
  }
  #gapSection {
    display: none;
  }
  .dashboard {
    width: 90%;
    max-width: 4000px !important;
  }
  .dashboardArticle {
    width: 90% !important;
    max-width: 4000px !important;
  }
  .questionBox {
    width: 90%;
  }
  .mobileLoginButton {
    display: none;
  }
  .dashboardBlog {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1500px) {
  .dashboardBlog {
    width: 90% !important;
  }
}

@media only screen and (max-width: 1100px) {
  #gapSection {
    display: none;
  }
  .aligned {
    display: flex;
    flex-direction: column;
  }
  #logo {
    margin: auto !important;
    margin-bottom: 20px !important;
  }
  .column {
    width: 100% !important;
  }

  .featured {
    height: 100px !important;
    width: 100px !important;
  }
  .oppPost {
    width: 500px !important;
    height: 120px !important;
  }
  .blogPost {
    width: 500px !important;
    height: 160px !important;
  }
  .mobileLoginButton {
    display: none;
  }
  .asdf {
    margin-top: -20px;
  }
  .quizButton {
    margin-left: 10px;
    margin-right: 10px !important;
  }
  .subjectButton {
    width: 100%;
  }
  .blogButton {
    width: 100%;
  }
}

@media only screen and (max-width: 850px) {
  .blogPost {
    height: auto !important;
  }
}

@media only screen and (max-width: 720px) {
  .oppPost {
    height: auto !important;
  }
}

@media only screen and (max-width: 600px) {
  .gotoBlogButton {
    margin-top: 20px;
  }

  .jsonBox {
    width: 100% !important;
  }

  .dashboard {
    width: 100%;
  }
  .onlyMobile {
    width: 100% !important;
  }
  .discordText {
    display: none !important;
  }
  .quizInfo {
    font-size: 1.1rem !important;
  }
  #subjectDescription {
    display: none !important;
  }
  .blog-media-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    grid-gap: 1rem;
  }
  .dashboardArticle {
    width: 100% !important;
    max-width: 4000px !important;
  }
  #nextButton {
    font-size: 17px !important;
  }
  #questionTitle {
    color: black;
    font-size: 18px !important;
  }
  .optionText {
    font-size: 19px;
  }
  .featured {
    margin-right: 15px !important;
    margin-left: 15px !important;
  }
  .dashboardBlog {
    width: 100% !important;
  }
  #socialSection {
    margin-top: 200px !important;
  }

  .subjectButton {
    width: 100%;
  }
  .blogButton {
    width: 100%;
  }
  .logoDescription {
    width: 100% !important;
  }
  #gapSection {
    display: inline-block !important;
  }
  #exitButton {
    display: none;
  }
  #nextButton {
    margin-left: 0px;
  }
  .dod-input {
    float: center !important;
    width: 100% !important;
  }
  .loginBox {
    width: 80% !important;
  }
  .loginButton {
    display: none;
  }
  .mobileLoginButton {
    border: 2px solid black;
    margin-bottom: 30px;
    background-color: #e7e7e7;
    border-radius: 17px;
    color: black;
    padding: 6px 14px;
    font-size: 16px;
    cursor: pointer;
    border-color: #e7e7e7;
    color: black;
    width: 100%;
    display: block;
  }

  .oppPost {
    width: 500px !important;
    height: auto !important;
  }
  .blogPost {
    width: 500px !important;
    height: auto !important;
  }
  .flex-container {
    display: flex;
    flex-direction: column !important;
  }
  .questionBox {
    width: 85% !important;
  }
  .questionBoxInner {
    width: 96% !important;
  }
  .instructions {
    width: 85% !important;
  }
  .quizButton {
    width: 175px !important;
    height: auto !important;
  }
}

@media only screen and (min-width: 1000px) {
  #exitButton {
    position: fixed !important;
    bottom: 40px !important;
    left: 50px !important;
  }

  #nextButton {
    position: fixed !important;
    bottom: 40px !important;
    right: 120px !important;
  }

  #revealAnswerButton {
    position: fixed !important;
    bottom: 40px !important;
    right: 170px !important;
  }
}

.links a {
  text-decoration: none;
  font-size: 1.2rem;
}

h1 {
  font-size: 2rem;
}

.orangeTitle {
  color: #e35f10;
  font-size: 2rem !important;
}

* {
  font-size: 1.1rem;
  font-family: Verdana;
  line-height: 2rem;
  box-sizing: border-box;
  max-width: 100%;
  margin: auto;
  /* 
  -webkit-touch-callout: none;
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none;
  user-select: none;*/
}
.blogButton {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 12px 24px;
  font-size: 17px;
  cursor: pointer;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.subjectButton {
  border: 2px solid black;
  background-color: white;
  color: black;
  padding: 12px 24px;
  font-size: 16px;
  cursor: pointer;
  border-color: #e7e7e7;
  color: black;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}
#NewsButton {
  background-color: #e6d3e0;
  border-color: #e6d3e0;
}
#InterviewButton {
  background-color: #d1deeb;
  border-color: #d1deeb;
}
#SchoolButton {
  background-color: #d2ebd1;
  border-color: #d2ebd1;
}

.subjectButton:hover {
  background-color: #eee;
}
#NewsButton:hover {
  background-color: #e6d3e0;
  border-color: #bf6ba5;
  cursor: pointer;
}
#InterviewButton:hover {
  background-color: #d1deeb;
  border-color: #8298ad;
  cursor: pointer;
}
#SchoolButton:hover {
  background-color: #d2ebd1;
  border-color: #93b891;
  cursor: pointer;
}
.noteButton {
  padding: 6px 18px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  /* 
  color: white;
  background-color: #eeeecf */
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 10px;
}

.subject {
  margin-bottom: 10px;
  /* 
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  */
}
#math {
  background-color: #f3e6d8 !important;
}
#physics {
  background-color: #eeeecf !important;
}
#cs {
  background-color: #bad0e6 !important;
}
#engineering {
  background-color: #dbd0e4 !important;
}
#chemistry {
  background-color: #cccddf !important;
}
#biology {
  background-color: #c0e4c4 !important;
}
#music {
  background-color: #e6cbd6 !important;
}
#other {
  background-color: #eee !important;
}
#humanities {
  background-color: #e4dddd !important;
}
.featured {
  height: 112px;
  width: 112px;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 17px;
  position: relative;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
}
.featured:hover {
  border: 2px dotted #e3d6c8;
}

.featuredTwo {
  height: 116px;
  width: 116px;
  overflow: hidden;
  border: 2px solid transparent;
  border-radius: 17px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
  margin-bottom: 10px;
  cursor: pointer;
}

.featuredTwo:hover {
  border: 2px dotted #e3d6c8;
}

.discordText {
  display: inline;
}

img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  width: auto;
  display: block;
  /* 
  display: block;
  margin-left: auto;
  margin-right: auto;
  */
}

.lazyloadImgParent {
  margin: auto;
}

.lazyloadImg {
  height: 200px;
  width: 200px;
  max-height: 100%;
  max-width: 100%;
  margin-right: 36px;
  border-radius: 17px;
  background-color: white;
}

#logo {
  object-fit: cover;
  height: 200px;
  width: 200px;
  margin-right: 36px;
  border-radius: 17px;
}
#subjectLogo {
  height: 125px;
  width: 125px;
  border-radius: 17px;
}

.aligned {
  display: flex;
  align-items: center;
}

.leftBox,
.rightBox {
  display: inline-block;
  text-align: center;
  position: center;
}

.column {
  float: left;
  width: 50%;
  padding: 10px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.blogText:hover {
  color: #e89600;
  cursor: pointer;
}

.loginButton {
  border: 2px solid black;
  background-color: #e7e7e7;
  color: black;
  padding: 4px 12px;
  font-size: 16px;
  cursor: pointer;
  border-color: #e7e7e7;
  border-radius: 14px;
  color: black;
  width: 90px;
  position: fixed !important;
  top: 40px !important;
  right: 50px !important;
}

/* 
.signupButton {
  border: 2px solid black;
  background-color: #e7e7e7;
  color: black;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-color: #e7e7e7;
  color: black;
  width: 110px;
  position: fixed !important;
  top: 40px !important;
  right: 50px !important;
}
*/

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}
.active,
.accordion:hover {
  background-color: #ccc;
}
.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.description {
  padding-left: 30px;
  padding-right: 30px;
  font-family: "Source Sans Pro";
  margin-top: 10px;
  margin-bottom: 25px;
}

#social {
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
}

.dod-input {
  -webkit-appearance: none;
  border: 1px solid #e8e8e8;
  border-radius: 0.25rem;
  padding: 0.2rem;
  float: right;
  width: 35%;
  font-size: 17px;
  margin-top: -10px;
  padding-left: 0.6rem;
}

.dod-media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 0.7rem;
}

.blog-media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

.quiz-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}

#normal {
  margin-left: 20px;
}
#compact {
  margin-left: 20px;
}

.instagramPost {
  width: 500px !important;
  height: auto;
  padding: none;
  font-family: Source Sans Pro;
  font-size: 17px;

  border: 1px #e9ecf0 solid;
}

.instagramPost:hover {
  border: 1px #8298ad solid;
}

.blogPost {
  background-color: #eee;
  width: 500px;
  height: 150px;
  padding-left: 10px;
  font-family: Source Sans Pro;
  padding: 20px;
  font-size: 17px;
  border: 2px white solid;
}
.quizInfo {
  background-color: #eee;
  width: 100%;
  height: auto;

  font-family: Source Sans Pro;
  padding: 20px;
  font-size: 17px;
  padding-bottom: 30px;
}

.oppPost {
  background-color: #eee;
  width: 500px;
  height: 120px;
  padding-left: 10px;
  font-family: Source Sans Pro;
  padding: 20px;
  font-size: 17px;
  border: 2px white solid;
}
.oppPost:hover {
  border: 2px darkgray solid;
}

.loginBox {
  -webkit-appearance: none;
  border: 1px solid #ccc5c5;
  border-radius: 0.25rem;
  padding: 0.2rem;
  width: 50%;
  font-size: 17px;
  margin-top: -10px;
  padding-left: 0.6rem;
}

.flex-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 1rem;
}

#gapSection {
  display: none;
}

input[type="radio"] {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  border: 2px solid gray;
  background-color: white;
  -webkit-appearance: none; /*to disable the default appearance of radio button*/
  -moz-appearance: none;
}

input[type="radio"]:focus {
  /*no need, if you don't disable default appearance*/
  outline: none; /*to remove the square border on focus*/
}

input[type="radio"]:checked {
  /*no need, if you don't disable default appearance*/
  background-color: gray;
}

input[type="radio"]:checked ~ span:first-of-type {
  color: white;
}

label span:first-of-type {
  position: relative;
  left: -27px;
  font-size: 15px;
  color: #1fbed6;
}

label span {
  position: relative;
  top: -12px;
}

/* 
input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
*/
/* 
input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

*/

/* 
.optionInput {
  display: none;
}
*/

.quizSettingDashboard {
  width: 80%;
  padding-top: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
  padding-bottom: 40px !important;
  max-width: 1000px !important;
}

#landingpage {
  width: 100%;
  padding: 20px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #f2f2f2;
}

.logoDescription {
  width: 80%;
  border-radius: 17px;
}

.quizDescription {
  width: 80%;
}
.quizButton {
  border: 2px solid white;
  width: 150px;
  height: auto;

  background-color: white;
  cursor: pointer;

  -webkit-appearance: none;
  overflow: visible;
  margin-bottom: 20px;
  border-radius: 17px;
}

#subjectDescription {
  font-family: "Source Sans Pro" !important;

  z-index: 900;
  position: relative;
  pointer-events: none;
  display: inline-block;
}

.dashboardBlog {
  width: 72%;
  padding-top: 1rem !important;
  padding: 2rem;
  background-color: transparent !important;
  padding-bottom: 40px !important;
  max-width: 1500px !important;
}

#socialSection {
  margin-top: 20px;
}

#blogFont {
  font-size: 18.5px;
}

b {
  font-size: 18px;
}
