.reactMarkDown ul {
  margin-top: 1em;
  margin-bottom: 1em;
  list-style: disc outside none;
}

.reactMarkDown ul li {
  margin-left: 2em;
  display: list-item;
  text-align: -webkit-match-parent;
}

.reactMarkDown * {
  font-family: "Source Sans Pro";
  text-align: left !important;
  width: 90%;
  font-size: 18px;
  line-height: 1.9rem;
  font-weight: 500;
}

.reactMarkDown h1 {
  line-height: 3rem;
  font-family: Verdana;
  box-sizing: border-box;
  max-width: 100%;
  font-size: 35px !important;
  margin: auto;
  font-family: "Kaisei Tokumin", serif;
}

@media only screen and (max-width: 1000px) {
  img {
    max-width: 100% !important;
  }
  .reactMarkDown h1 {
    line-height: 2.6rem;
    font-family: Verdana;
    box-sizing: border-box;
    max-width: 100%;
    font-size: 28px !important;
    margin: auto;
    font-family: "Kaisei Tokumin", serif;
  }
}

.reactMarkDown img {
  display: block;
  margin: 0 auto;
  width: 80%;
}

.reactMarkDown b {
  font-weight: bold !important;

  font-size: 19px;
}

a {
  text-decoration: none;
  color: purple;
}
